
    // ============================================================================================
    import { metaFinder } from "~/mixins/pages/finder/metaFinder";
    import { pageFinder } from "~/mixins/pages/finder/pageFinder";
    import { themeFinder } from "~/mixins/pages/finder/themeFinder";

    /**
     * @author Jason Curren
     * @description Main home page for event
     * ============================================================================================
     */
    export default {
        auth: false,
        layout: "sub",
        mixins: [metaFinder, pageFinder, themeFinder],
        name: "EventPage",
        transitions: "page",
        data() {
            return {
                basket: [],
                compile: false,
                creator: true,
                confirmClose: false,
                eventProduct: {},
                form: {},
                loading: false,
                items: [],
                invalidPromoCode: false,
                invalidAddress: false,
                product: {},
                promo: {},
                product_id: 0,
                selectedProduct: {},
                eventProductPrice: 0,
                thankYou: false,
                productAddedToCart: false,
                errorMessages: [],
                order: {},
                products: {}
            }
        },
        methods: {
            // addProduct(products) {
            //     this.$store.commit("basket/ADD_PRODUCT", {
            //         ...products, quantity: products.products.store.quantity, product_id: this.product_id, price: this.eventProductPrice, store_default_vat: products.products.store.store_default_vat
            //     });

            //     this.compile = false;

            //     this.closeModalReally(true);
            // },
            // addToBasket() {
            //     this.compile = true;
            // },
            checkAddressError(res) {
                if (res.data && res.data.message && res.data.message === "It looks like you have ordered your gift already.") {
                    this.invalidAddress = true;
                    this.loading = false;
                    return true;
                } else {
                    return false;
                }
            },
            checkPromoCodeFail(res) {
                if (res.data && res.data.message && res.data.message === "Invalid promo code") {
                    this.invalidPromoCode = true;
                    this.loading = false;
                }
            },
            closeModal() {
                this.creator && !this.loading ? this.confirmClose = true : this.closeModalReally();
            },
            closeModalReally(addToCart = false) {
                this.$store.commit("toggle/SET_TOGGLER", { target: "modal", value: "" });

                this.confirmClose = false;
                this.creator = true;
                this.basket = [];
                this.product = {};
                this.product_id = 0;
                this.thankYou = false;

                if(!addToCart) {
                    this.productAddedToCart = false;
                    window.location.reload();
                } else {
                    this.productAddedToCart = true;
                }
            },
            orderPromoProductAsConsumer(products) {
                this.basket.push(products);
                this.creator = false;
            },
            postForm(payload) {
                const { basket } = payload;

                delete payload.basket;

                this.form = payload;

                this.invalidPromoCode = false;

                this.loading = true;

                // Make a deep copy of the product object, otherwise replacing won't work.
                const updatedBasketProduct = JSON.parse(JSON.stringify(basket[0]));

                if (updatedBasketProduct.products?.images) {
                    Object.keys(updatedBasketProduct.products.images).forEach((key) => {
                        const image = updatedBasketProduct.products.images[key];

                        // Check if the image string is in base64 format (others are S3 links).
                        if (typeof image === 'string' && image.startsWith('data:image/')) {
                            // Convert and replace the base64 with a Blob.
                            const contentType = image.substring(image.indexOf(':') + 1, image.indexOf(';'));
                            const blob = this.base64ToBlob(image, contentType);
                            updatedBasketProduct.products.images[key] = blob;
                        }
                    });
                }
                updatedBasketProduct.custom_text_front = updatedBasketProduct.products.front.some(canvasObject => canvasObject.customisable);
                updatedBasketProduct.custom_text_back = updatedBasketProduct.products.back.some(canvasObject => canvasObject.customisable);

                // Prepare the initial request object.
                const preparedRequest = {
                    ...payload,
                    products: [updatedBasketProduct]
                };

                // Convert the preparedRequest object to FormData.
                const formData = new FormData();
                this.appendNestedDataToFormData(formData, preparedRequest);
                formData.append('lang', this.$i18n.locale);

                this.errorMessages = [];
                this.invalidPromoCode = false;
                this.invalidAddress = false;

                this.$axios.post(`/api/${this.$store.state.tenant.tenant}${this.$route.path}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then(res => {
                    this.creator = true;
                    this.basket = {};
                    this.thankYou = true;
                    this.order = res.data.data.order;
                    this.products = res.data.data.products;

                    this.$store.commit("toggle/SET_TOGGLER", { target: "modal", value: "" });
                })
                .catch(err => {
                    console.log(err);
                    if (err.response?.data?.errors) {
                        const errors = err.response.data.errors;

                        this.errorMessages.push(...Object.entries(errors));
                        this.checkAddressError(err.response || {});
                        this.checkPromoCodeFail(err.response || {});

                    }
                    if (!this.checkAddressError(err.response || {})) {
                        this.checkPromoCodeFail(err.response || {});
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
            },
            /**
             * Populates the preparedRequest object with data from the basket.
             * @param {Object} preparedRequest - The object to be populated with basket data.
             * @returns {undefined} The object is passed by reference and reassignment is not necessary.
             */
             addBasketDataToPreparedRequest(preparedRequest) {
                // Convert any base64 images to blobs.
                this.$store.dispatch('basket/convertBase64ImagesToBlobs');

                // Iterate over each product in the basket and add its data to preparedRequest.
                this.$store.state.basket.products.forEach((product) => {
                    const productData = {
                        description: product.description,
                        name: product.name,
                        price: product.price,
                        product_id: product.product_id,
                        quantity: product.quantity,
                        status: product.status,
                        store_default_vat: product.store_default_vat,
                        store_selling_price: product.store_selling_price,
                        custom_text_front: product.products.front.some(canvasObject => canvasObject.customisable),
                        custom_text_back: product.products.back.some(canvasObject => canvasObject.customisable),
                        products: product.products,
                    };

                    preparedRequest.products.push(productData);
                });
            },
            appendNestedDataToFormData(formData, data, parentKey = '') {
                for (let key in data) {
                    if (data.hasOwnProperty(key)) {
                        const value = data[key];
                        const formKey = parentKey ? `${parentKey}[${key}]` : key;

                        if (value instanceof Blob) {
                            // Append Blob objects (e.g., files, images)
                            formData.append(formKey, value, value.name || 'blob');
                        } else if (Array.isArray(value)) {
                            // Handle arrays, including empty ones
                            if (value.length === 0) {
                                // Append an empty array by sending the key without any value
                                formData.append(formKey, '');
                            } else {
                                // Append each element of the array
                                value.forEach((item, index) => {
                                    if (typeof item === 'object' && item !== null) {
                                        // Recursively process nested objects or arrays
                                        this.appendNestedDataToFormData(formData, item, `${formKey}[${index}]`);
                                    } else {
                                        // Append simple types (string, number, etc.)
                                        formData.append(`${formKey}[${index}]`, item);
                                    }
                                });
                            }
                        } else if (typeof value === 'object' && value !== null) {
                            // Recursively process nested objects
                            this.appendNestedDataToFormData(formData, value, formKey);
                        } else {
                            // Append simple types (string, number, null, etc.)
                            formData.append(formKey, value);
                        }
                    }
                }
            },
            base64ToBlob(base64, mimeType = 'image/png') {
                // Method: https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
                // Remove the Base64 prefix if it exists.
                const base64Data = base64.split(',')[1] || base64;

                // Decode the Base64 string into a binary string.
                const byteCharacters = atob(base64Data);

                // Create an array to hold the byte data.
                const byteNumbers = new Array(byteCharacters.length);

                // Convert each character to a byte number.
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }

                // Convert the byte numbers array to a Uint8Array.
                const byteArray = new Uint8Array(byteNumbers);

                // Create a Blob with the given mime type.
                return new Blob([byteArray], { type: mimeType });
            },
            setPrice() {
                const eventProduct = this.event_products.find(
                    eventProduct => eventProduct.id === this.product_id
                );

                this.eventProductPrice = eventProduct?.event_prices.find(
                    price => price.event_id === this.data.id
                )?.store_selling_price;
            },
            viewBasket() {
                this.$store.commit("basket/SET_BASKET");
            },
            viewProduct(val) {
                const { id, products } = val;

                this.$store.commit("product/UPDATE_PRODUCT", val);

                this.eventProduct = val;

                this.items = products.items;

                this.product = products;

                this.product_id = id;

                this.setPrice();

                this.selectedProduct = products.selected;

                this.$store.commit("toggle/SET_TOGGLER", { target: "modal", value: "full" });
            }
        }
    }
