import { render, staticRenderFns } from "./index.vue?vue&type=template&id=4d7f54d2"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ErrorNot: require('/usr/src/eventum-portal/components/error/not.vue').default,ThankYou: require('/usr/src/eventum-portal/components/thank/you.vue').default,ModalCreator: require('/usr/src/eventum-portal/components/modal/creator.vue').default,IconLoader: require('/usr/src/eventum-portal/components/icon/IconLoader.vue').default,CreatorArea: require('/usr/src/eventum-portal/components/creator/CreatorArea.vue').default,FormConsumer: require('/usr/src/eventum-portal/components/form/consumer.vue').default,ModalOverlay: require('/usr/src/eventum-portal/components/modal/ModalOverlay.vue').default,TemplateContainer: require('/usr/src/eventum-portal/components/template/TemplateContainer.vue').default})
