
    import ProductImageConsumerStoreAndCart from "@/components/display/ProductImageConsumerStoreAndCart";

    export default {
        name: "ThankYou",
        components: {
            ProductImageConsumerStoreAndCart
        },
        props: {
            order: {},
            products: {}
        },
        data() {
            return {
                loading: false,
            }
        },
    }
