/**
 * @author Jason Curren
 * @description Finds meta information based on details passed into the page
 * ================================================================================================
 */
export const metaFinder = {
    head() {
        return {
            title: this.meta.title ? this.meta.title : "Events",
            meta: [
                {
                    hid: "description",
                    name: "description",
                    content: this.meta.meta_description ? this.meta.meta_description : ""
                },
                {
                    hid: "keywords",
                    name: "keywords",
                    content: this.meta.keywords ? this.meta.keywords.join(",") : ""
                },
                {
                    id: "author",
                    name: "author",
                    content: "T Shirt & Sons"
                },
                {
                    hid: "url",
                    property: "og:url",
                    content: this.meta.canonical ? this.meta.canonical : ""
                },
                {
                    hid: "type",
                    property: "og:type",
                    content: "article"
                },
                {
                    hid: "og:title",
                    property: "og:title",
                    content: this.meta.title ? this.meta.title : ""
                },
                {
                    hid: "og:description",
                    property: "og:description",
                    content: this.meta.meta_description ? this.meta.meta_description : ""
                },
                {
                    hid: "image",
                    property: "og:image",
                    content: this.meta.ogImage ? this.meta.ogImage : ""
                }
            ],
            link: [
                {
                    rel: "canonical",
                    href: this.meta.canonical ? this.meta.canonical : ""
                }
            ]
        }
    }
}