/**
 * @author Jason Curren
 * @description Finds page, meta and theme information and serverside renders the information
 * ================================================================================================
 * @returns Object
 */
export const pageFinder = {
    async asyncData({ req }) {
        if (process.server) {
            var host = req.headers.host;

            return { host };
        }
    },
    data() {
        return {
            data: {},
            event_products: [],
            event_type: {},
            expired: false,
            files: [],
            notFound: false,
            meta: {},
            page: {},
            theme: {}
        }
    },
    mounted() {
        if (this.host) this.formatHost();
    },
    methods: {
        assignEventTypes() {
            this.files = this.data.files;
            this.event_products = this.data.event_products;
            this.event_type = this.data.event_type;
            this.expired = this.data.expired || false;

            this.assignEventTypeNest();
        },
        assignEventTypeNest() {
            this.meta = this.event_type.meta;
            this.page = this.event_type.page;
            this.theme = this.event_type.theme;

            this.checkEventDefaultLang();
            this.checkEventName();
            this.checkEventType();
            this.checkEventRegion();
            this.checkEventVat();
            this.checkEventVatRegistered();
            this.checkCountries();
            this.checkStates();

            this.updateFonts();
            this.updateTheme();

            this.$store.dispatch('basket/fetchBasketData');
        },
        checkEventDefaultLang() {
            // Check if the default language has already been set in this session
            const isDefaultLangSet = sessionStorage.getItem("defaultLangSet");

            if (!isDefaultLangSet) {
                // Get event lang, default to English if none found
                const defaultLang = (this.data && this.data.default_event_lang && this.data.default_event_lang !== "[]") ? this.data.default_event_lang : "en";

                // Set the lang
                this.$store.commit("translation/UPDATE_LANG", defaultLang);
                this.$i18n.setLocale(defaultLang);

                // Mark the default language as set in sessionStorage to prevent overriding user lang selection
                sessionStorage.setItem("defaultLangSet", "true");
            } else {
                this.$i18n.setLocale(this.$store.state.translation.lang);
            }
        },
        checkEventName() {
            if (this.data && this.data.name) {
                this.$store.commit("event/UPDATE_EVENT_NAME", this.data.name);
                this.$store.commit("basket/UPDATE_BASKET_NAME", this.data.name);
            }
        },
        checkEventType() {
            if (this.event_type && this.event_type.type) {
                this.$store.commit("event/UPDATE_EVENT_TYPE", this.event_type.type);
            }
        },
        checkEventRegion() {
            if (this.data && this.data.region) {
                this.$store.commit("event/UPDATE_EVENT_REGION", this.data.region);
                this.setEventCurrency(this.data.region);
            }
        },
        checkEventVat() {
            if (this.data && this.data.vat_rate) {
                this.$store.commit("event/UPDATE_EVENT_VAT_RATE", this.data.vat_rate);
            }
        },
        checkEventVatRegistered() {
            if (this.data && this.data.not_vat_registered) {
                this.$store.commit("event/UPDATE_NOT_VAT_REGISTERED", this.data.not_vat_registered);
            }
        },
        checkCountries() {
            if (this.data && this.data.countries) {
                this.$store.commit("countries/UPDATE_COUNTRIES", this.data.countries);
            }
        },
        checkStates() {
            if (this.data && this.data.states) {
                this.$store.commit("countries/UPDATE_STATES", this.data.states);
            }
        },
        formatHost() {
            let split = this.host.split(".");

            const tenant = split[0];

            this.$store.commit("tenant/UPDATE_TENANT", tenant);

            this.getData(tenant);
        },
        getData(tenant) {
            this.$axios.get(`/api/${tenant}/${this.$route.params.slug}`, {
            }).then(res => {
                this.data = { ...res.data.data };

                this.assignEventTypes();
            }).catch(err => {
                console.log(err);

                this.notFound = true;
            });
        },
        setEventCurrency(region) {
            if (region === 'UK') {
                this.$store.commit("event/UPDATE_EVENT_CURRENCY", '£');
            }
            else if (region === 'EU') {
                this.$store.commit("event/UPDATE_EVENT_CURRENCY", '€');
            }
            else if (region === 'US') {
                this.$store.commit("event/UPDATE_EVENT_CURRENCY", '$');
            }
        },
    }
}