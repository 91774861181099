
    export default {
        name: "Error404",
        props: {
            error: {
                default: () => ({}),
                type: Object
            }
        }
    }
